import { createSlice } from '@reduxjs/toolkit';
import { PreparedState } from '../../@types/prepared';

const initialState: PreparedState = {
  isLoading: false,
  preparedList: {},
  preparedListParams: {},
  preparedRowList: {},
  preparedRowListParams: {},
};

const slice = createSlice({
  name: 'prepared',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getPreparedListSuccess(state, action) {
      state.isLoading = false;
      state.preparedList = action.payload;
    },

    getPreparedSuccess(state, action) {
      state.isLoading = false;
      state.prepared = action.payload;
    },

    setPreparedListParams(state, action){
      state.preparedListParams = action.payload;
    },

    addPreparedSuccess(state, action){
      state.preparedList = {
        meta: state.preparedList.meta,
        data: [action.payload, ...state.preparedList.data || []]
      }
    },

    editPreparedSuccess(state, action) {
      state.preparedList = {
        meta: state.preparedList.meta,
        data: state.preparedList.data?.map(obj => obj.id === action.payload.id ? action.payload : obj)
      }
    },

    deletePreparedSuccess(state, action){
      state.preparedList = {
        meta: state.preparedList.meta,
        data: state.preparedList.data?.filter(obj => obj.id !== action.payload.id)
      }
    },

    getPreparedRowListSuccess(state, action) {
      state.isLoading = false;
      state.preparedRowList = action.payload;
    },

    setPreparedRowListParams(state, action){
      state.preparedRowListParams = action.payload;
    },

    getPreparedRowSuccess(state, action) {
      state.isLoading = false;
      state.preparedRow = action.payload;
    },

    editPreparedRowSuccess(state, action) {
      state.preparedRowList = {
        meta: state.preparedRowList.meta,
        data: state.preparedRowList.data?.map(obj => obj.id === action.payload.id ? action.payload : obj)
      }
    },

    deletePreparedRowSuccess(state, action){
      state.preparedRowList = {
        meta: state.preparedRowList.meta,
        data: state.preparedRowList.data?.filter(obj => obj.id !== action.payload.id)
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading, hasError, resetError, getPreparedListSuccess, getPreparedSuccess,
  setPreparedListParams, addPreparedSuccess, editPreparedSuccess, deletePreparedSuccess,
  getPreparedRowListSuccess, setPreparedRowListParams, getPreparedRowSuccess, editPreparedRowSuccess, deletePreparedRowSuccess
} = slice.actions;

