// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  dashboard: getIcon('ic_dashboard'),
  invoice: getIcon('ic_cart'),
  items: getIcon('ic_menu_item'),
  video: getIcon('ic_kanban')
};

const navConfig = [
  {
    subheader: 'Общее',
    items: [
      { title: 'Данные', path: PATH_DASHBOARD.data.root, icon: ICONS.dashboard },
      { title: 'Свод данных', path: PATH_DASHBOARD.prepared.root, icon: ICONS.dashboard },
      { title: 'Модели', path: PATH_DASHBOARD.models.root, icon: ICONS.dashboard },
      { title: 'Анализ', path: PATH_DASHBOARD.analysis, icon: ICONS.dashboard },
    ],
  },
  {
    subheader: 'Администрирование',
    items: [
      {
        title: 'Пользователи',
        path: PATH_DASHBOARD.users.root,
        icon: ICONS.user,
      },
    ],
  },
  {
    subheader: 'Аккаунт',
    items: [
      { title: 'Профиль', path: PATH_DASHBOARD.account, icon: ICONS.user },
      { title: 'Выйти', path: PATH_AUTH.login, icon: ICONS.dashboard },
    ],
  },
];

export default navConfig;
