import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import sectionReducer from './slices/section';
import experimentReducer from './slices/experiment';
import analysisReducer from './slices/analysis';
import preparedReducer from './slices/prepared';
import modelReducer from './slices/model';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  user: userReducer,
  section: sectionReducer,
  experiment: experimentReducer,
  analysis: analysisReducer,
  prepared: preparedReducer,
  model: modelReducer,
});

export { rootPersistConfig, rootReducer };
