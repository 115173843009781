import { createSlice } from '@reduxjs/toolkit';
import { ModelState } from '../../@types/model';

const initialState: ModelState = {
  isLoading: false,
  modelList: {},
  modelListParams: {},
};

const slice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    getModelListSuccess(state, action) {
      state.isLoading = false;
      state.modelList = action.payload;
    },

    getModelSuccess(state, action) {
      state.isLoading = false;
      state.model = action.payload;
    },

    setModelListParams(state, action){
      state.modelListParams = action.payload;
    },

    addModelSuccess(state, action){
      state.modelList = {
        meta: state.modelList.meta,
        data: [action.payload, ...state.modelList.data || []]
      }
    },

    editModelSuccess(state, action) {
      state.modelList = {
        meta: state.modelList.meta,
        data: state.modelList.data?.map(obj => obj.id === action.payload.id ? action.payload : obj)
      }
    },

    deleteModelSuccess(state, action){
      state.modelList = {
        meta: state.modelList.meta,
        data: state.modelList.data?.filter(obj => obj.id !== action.payload.id)
      }
    },

    defaultModelSuccess(state, action){
      state.modelList = {
        meta: state.modelList.meta,
        data: state.modelList.data?.map(obj => ({
          ...obj,
          default: obj.id === action.payload.id
        }))
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading, hasError, resetError, getModelListSuccess, getModelSuccess,
  setModelListParams, addModelSuccess, editModelSuccess, deleteModelSuccess, defaultModelSuccess
} = slice.actions;

