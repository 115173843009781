import { createSlice } from '@reduxjs/toolkit';
import { AnalysisState } from '../../@types/analysis';

const initialState: AnalysisState = {
  isLoading: false,
  seriesList: [],
  dictPreparedList: [],
  dictModelList: [],
};

const slice = createSlice({
  name: 'analysis',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    resetError(state) {
      state.error = undefined;
    },

    addSeriesLineSuccess(state, action) {
      state.isLoading = false;
      state.seriesList = [...state.seriesList, action.payload];
    },

    removeSeriesLineSuccess(state, action) {
      state.isLoading = false;
      state.seriesList = state.seriesList.filter(line => line.source_id !== action.payload.source_id || line.type !== action.payload.type);
    },

    getDictSuccess(state, action) {
      const data = action.payload.data
      state.dictPreparedList = data.prepared_list || [];
      state.dictModelList = data.model_list || [];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading, hasError, resetError,
  addSeriesLineSuccess, removeSeriesLineSuccess,
  getDictSuccess
} = slice.actions;

